import { translationService } from "@react3l/react3l/services";
import vi from "i18n/vi.json";
import { GoogleAuthProvider } from "Page/PageContext";
import React from "react";
import ReactDOM from "react-dom";
import addReactNDevTools from "reactn-devtools";
import * as serviceWorker from "service-worker";
import "styles";
import nameof from "ts-nameof.macro";
import WebFont from 'webfontloader';

WebFont.load({
  google: {
    families: ['Montserrat', 'sans-serif']
  }
});

if (process.env.NODE_ENV === "development") {
  addReactNDevTools();
}

const Page = React.lazy(async () => {
  await translationService.initTranslation();
  await translationService.changeLanguage(nameof(vi), vi);

  return import("Page/Page");
});

ReactDOM.render(
  <React.Suspense fallback={null}>
    <GoogleAuthProvider>
      <Page />
    </GoogleAuthProvider>
  </React.Suspense>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
